'use client'

import { Dialog, DialogPanel } from '@headlessui/react'
import MobileMenu from "./MobileMenu";

export default function CustomDrawer({ showBurger, setShowBurger }) {
  return (
    <Dialog open={showBurger} onClose={setShowBurger} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full mt-[130px]">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-3 shadow-xl">
                <div className="relative flex-1 px-4 sm:px-6">
                  <MobileMenu />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
