import Email from './../images/mail.svg';
import Phone from './../images/phone-call.svg';
import { useFormik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";
import React, {useState} from "react";
import ErrorMassage from "./ErrorMassage";
import GoTo from "./GoTo";

const ContactUsBlock = () => {
  const [errors, setErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      message: '',
    },

    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://admin.sargisbabayan.com/api/contact', values);
        console.log('Form submitted successfully:', response.data);
        toast.success('Your message has been send, waiting for answer from admin')
      } catch (error) {
        setErrors(error.response.data.errors);
        console.error('Error submitting form:', error);
      }
    },
  });


  return (
    <div className="space-y-10 w-full mb-[80px]">
      <p className="text-4xl md:text-6xl font-normal font-custom text-center text-darkBg dark:text-lightBg">CONTACTS</p>

      <div className="flex flex-col md:flex-row justify-around items-center items-baseline px-10 md:px-20">
        <div className="min-w-[300px] md:min-w-[300px] lg:min-w-[550px]">
          <div>
            <p className="text-darkBg dark:text-lightBg border-l border-[#E1C17B] px-4">CALL FOR ADVICE NOW!</p>
            <div className="flex gap-x-7 items-center mt-5">
              <img alt="png" src={Phone} />
              <p className="text-[#858585]">(+374) 77 083388</p>
            </div>
          </div>
          <div className="mt-20">
            <p className="text-darkBg dark:text-lightBg border-l border-[#E1C17B] px-4">SAY HELLO!</p>
            <div className="flex gap-x-7 items-center mt-5">
              <img alt="png" src={Email} />
              <p className="text-[#858585]">Sargis.bab@gmail.com</p>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="w-full">
          <div className="space-y-6 flex flex-col mt-14 md:mt-[54px]">
            <input
              name="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              className="border-b border-[#E1C17B] bg-transparent h-[45px] max-w-[335px] md:max-w-[650px] px-[20px] w-full text-white outline-none"
              placeholder="Name"
            />
            { errors.full_name && <ErrorMassage message={errors.full_name} /> }

            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="border-b border-[#E1C17B] bg-transparent h-[45px] max-w-[335px] md:max-w-[650px] px-[20px] w-full text-white outline-none"
              placeholder="Email"
            />
            { errors.email && <ErrorMassage message={errors.email} /> }

            <input
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              className="border-b border-[#E1C17B] bg-transparent h-[45px] max-w-[335px] md:max-w-[650px] px-[20px] w-full text-white outline-none"
              placeholder="Phone number"
            />
            { errors.phone && <ErrorMassage message={errors.phone} /> }

            <textarea
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              className="border-b border-[#E1C17B] bg-transparent max-w-[335px] md:max-w-[650px] px-[20px] flex items-center justify-center text-white outline-none" rows="4"  cols="50"
              placeholder="Message"
            />
            { errors.message && <ErrorMassage message={errors.message} /> }

            <div className="flex justify-center max-w-[650px]">
             <button className="mt-3.5 rounded-[30px] border border-[#E1C17B] h-[40px] text-darkBg dark:text-lightBg px-10 font-bold">Send us a message</button>
            </div>
            <GoTo title="ABOUT SARGIS BABAYAN" to="/about"/>
          </div>
        </form>
        </div>
    </div>
  );
};

export default ContactUsBlock;
