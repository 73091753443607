import {useEffect} from "react";

const UserAgreement = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">Пользовательское соглашение</h1>
      <h3 className="font-bold text-2xl mt-4 text-center">1. Общие условия</h3>
<p>
  <p><br/></p>  1.1. Настоящее Пользовательское соглашение (далее – «Соглашение») определяет условия
  использования Платформы DOM FOOD (ДОМ ФУД) (далее – «Платформа»), а также права и
  обязанности Пользователей и Администрации Платформы. Действующая редакция Соглашения
  размещена на официальных сайтах https://dom-food.com и https://domfood.kz.
  <p><br/></p>  1.2. Применение к третьим лицам: Соглашение распространяется на отношения, которые
  могут затрагивать права и интересы третьих лиц, не являющихся Пользователями, если такие права
  и интересы затрагиваются действиями Пользователей.
  <p><br/></p> 1.3. Предмет соглашения: Администрация Платформы предоставляет Пользователю доступ к
  использованию функционала Платформы.
  <p><br/></p> 1.4. Использование Платформы: Использование Платформы в любой форме (просмотр
  материалов, регистрация, размещение информации) означает присоединение к договору в
  соответствии со ст. 395 и 396 Гражданского кодекса РК.
  <p><br/></p>  1.5. Принятие условий:
  <p><br/></p>  1.5.1.Пользователь подтверждает, что:
  <p><br/></p>  1.5.1.1. Ознакомился с условиями Соглашения до начала использования Платформы.
  <p><br/></p>  1.5.1.2. Принимает условия Соглашения без ограничений и обязуется их соблюдать.
  <p><br/></p>  1.5.1.3. Прекращает использование Платформы в случае несогласия с условиями
  Соглашения.
  <p><br/></p>  1.6. Право на изменение соглашения: Платформа DOM FOOD вправе вносить изменения в
  Соглашение без уведомления Пользователей. Новая редакция вступает в силу с момента
  размещения на Платформе или направления уведомления на email, указанный при регистрации.
  <p><br/></p>  1.7. В настоящем Соглашении применяются следующие термины и определения:
  <p><br/></p>  1.7.1.Товар – готовые блюда еды, приготовленные партнерами Платформы по заказу
  Пользователя, посредством Платформы.
  <p><br/></p>  1.7.2.Платформа — интернет-сайт, размещенный в домене https://dom-food.com и
  https://domfood.kz, который соединяет Пользователей, Партнеров и Курьеров-партнеров с целью
  реализации товаров и услуг партнеров Платформы ее Пользователи.
  <p><br/></p>  1.7.3.Пользователь — дееспособное физическое лицо, достигшее 18 лет, присоединившееся к
  настоящему Соглашению в собственном интересе либо юридическое лицо в лице своего
  представителя, выступающего от имени и в интересах представляемого им юридического лица,
  которые оформляют заказы на товары и услуги через Платформу.
  <p><br/></p>  1.7.4.Администрация Платформы/Администрация - Общество с ограниченной ответственностью
  DOM FOOD «ДОМ ФУД», юридическое лицо, созданное по законодательству Республики
  Армения и зарегистрированное по адресу: Республика Армения, город Ереван, ул.Чайковского,
  дом 34, квартира 19. Кентрон, 0010 и Товарищество с ограниченной ответственностью «DOM
  FOOD (ДОМ ФУД)», в лице Директора Евлоева Абдулла Аслановича, действующего на основании
  Устава, БИН 240940031813, адрес места нахождения: Казахстан, город Алматы, Алмалинский
  район, улица Карасай Батыра, дом 193Б, почтовый индекс 050008.
  <p><br/></p> 1.7.5.Сервис — комплекс услуг, предоставляемые Пользователю с использованием Платформы.
  <p><br/></p>  1.7.6.Аккаунт/учетная запись — это персональная страница Пользователя или личный кабинет,
  который создается после регистрации на Платформе.
  <p><br/></p>  1.7.7.Авторизация – вход Пользователя в профиль в Личный Кабинет с помощью логина и пароля.
  <p><br/></p>  1.7.8.Пароль, присваиваемый Пользователю по итогам завершения процесса регистрации.
  <p><br/></p>  1.7.9.Соглашение - настоящее соглашение со всеми дополнениями и изменениями.
  <p><br/></p>  1.7.10. Партнер - ресторан, розничный магазин или иного рода партнер по обслуживанию,
  подписавший партнерское соглашение с Платформой и предлагающий в качестве продавца свои
  товары – блюда готовой еды и услуги доставки или без доставки для продажи через Платформу, а
  также курьер-партнер - независимый подрядчик, подписавший партнерское соглашение с
  Платформой и являющийся физическим или юридическим лицом, или его представителем,
  оказывающим услуги доставки через Платформу.
  <p><br/></p> 1.8. Обращения, предложения и претензии физических и юридических лиц к Администрации
  Платформы в связи с настоящим Соглашением и всеми вопросами по функционированию
  Платформы, нарушениями прав и интересов третьих лиц при использовании Платформы, а также
  для запросов уполномоченных лиц могут быть направлены на почтовый адрес Администрации
  Платформы, указанный в пункте 1.7 настоящего раздела.
  <p><br/></p> 1.9. В отношении функционирования и развития Платформы Администрация Платформы
  руководствуется законодательством Республики Казахстан, настоящим Соглашением и иными
  специальными документами, которые разработаны или могут быть разработаны и приняты
  Администрацией Платформы в целях регламентации предоставления Пользователям доступа к
  отдельному функционалу Платформы.
  <p><br/></p> 1.10. Никакие положения настоящего Соглашения не предоставляют Пользователю право на
  использование фирменного наименования, товарных знаков, доменных имен и иных отличительных
  знаков Администрации Платформы и иных Пользователей. Право на использование фирменного
  наименования, товарных знаков, доменных имен и иных отличительных знаков Администрации
  Платформы, а также иных Пользователей может быть предоставлено исключительно по
  письменному соглашению с Администрацией Платформы и иными Пользователями
  соответственно.
  <p><br/></p> 1.11. Пользователь соглашается получать посредством Платформы и/или сервисов третьих лиц
  через email, SMS, и иными способами сервисные сообщения, в том числе сообщения о введении в
  действие новых либо отмене старых функций Платформы, сообщения о прохождении опросов, в
  том числе от партнёров Администрации Платформы.
  <p><br/></p>  1.12. В случае обнаружения факта нарушения Пользователем законных прав и интересов
  Администрации Платформы и/или третьих лиц, действующего законодательства Республики
  Казахстан, а также положений настоящего Соглашения Администрация Платформы вправе по
  своему выбору принимать следующие меры как по отдельности, так и при необходимости
  совместно в соответствии с политикой Администрации Платформы и в зависимости от характера и
  частоты соответствующих нарушений:
  <p><br/></p>  1.12.1. удалять Контент и иную информацию и/или блокировать доступ к ним;
  <p><br/></p>  1.12.2. заблокировать или удалить персональную страницу Пользователя.
  <p><br/></p>  1.13. Настоящее Соглашение составлено на русском и казахском языках. При необходимости и по
  усмотрению Администрации, Соглашение может быть переведено на иные языки. В случае
  разночтений, основным считается текст Соглашения, изложенный на русском языке.
  <p><br/></p>  1.14. Администрация Платформы не хранит платежные данные Пользователей — их обработка и
  хранение осуществляется поставщиком платежных услуг.
  <p><br/></p>  1.15. Администрация оставляет за собой право обновлять функционал Платформы, добавлять или
  удалять услуги и функции, информируя об этом Пользователей заранее через доступные каналы
  связи (электронная почта, уведомления в приложении). Внесённые изменения вступают в силу с
  момента их опубликования.
  <p><br/></p> 1.16. Посредством Платформы строго запрещена реализация алкогольной продукции, а также
  продукции, содержащей алкоголь; сигареты и иная табачная продукция, наркотические и иные
  вещества, запрещенные или ограниченные в обороте на территории Республики Казахстан; живые
  насекомые, животные, пресмыкающиеся; сырые мясные продукты и полуфабрикаты из них. По
  усмотрению Администрации Платформы, к реализации могут быть запрещены и иные товары.
  <p><br/></p>  1.17. Пользователи подтверждают понимание того, что Платформа является простым
  посредником между Пользователем и партнерами, и не принимает на себя никакой ответственности,
  вытекающей из информации, предоставленной Пользователями и партнерами, а также не
  принимает на себя никакой ответственности за любые убытки или ущерб, которые могут быть
  понесены Пользователями в связи с осуществлением партнерами своей деятельности посредством
  Платформы, а также с нарушением настоящих Общих условий.
  <p><br/></p> 1.18. При размещении Пользователем заказа, Платформа не является стороной отношений между
  Пользователем и партнерами, а лишь предоставляет интернет-площадку в виде Платформы
  (приложение), позволяющую Сторонам общаться и размещать заказы в необходимом количестве.
  <p><br/></p> 1.19. Администрация Платформы не делает никаких заверений или подтверждений
  относительно своих партнеров, их личности или опыта. Поэтому Администрация Платформы
  рекомендует Пользователям всегда руководствоваться здравым смыслом и сохранять
  бдительность при размещении любых заказов.
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. Создание учетных записей</h3>
      <p>
        <p><br/></p> 2.1. Кто может быть Пользователем: Пользователем Платформы может быть только
        дееспособное физическое лицо, достигшее 18 лет, действующее в собственных интересах, или
        юридическое лицо, представленное своим уполномоченным представителем.
        <p><br/></p> 2.1.1.Регистрация несовершеннолетних лиц, даже через их законных представителей, не
        допускается.
        <p><br/></p>  2.1.2.Администрация Платформы имеет право запросить документы для подтверждения возраста,
        личности, а также статуса пользователя как индивидуального предпринимателя или юридического
        лица
        <p><br/></p>  2.1.3.Подача таких документов не накладывает на Администрацию обязанности по проверке
        достоверности информации, и Администрация не несет ответственности за возможную
        недостоверность предоставленных данных или незаконные действия Пользователя.
        <p><br/></p>  2.2. Учетные записи: Каждый Пользователь может иметь только одну учетную запись:
        Создание и использование нескольких аккаунтов запрещено. Нарушение этого правила приведет к
        блокировке всех учетных записей.
        <p><br/></p>   2.3. Регистрация и согласие с Соглашением: Для регистрации на Платформе необходимо
        пройти первичную регистрацию на платформе DOM FOOD, либо на сайтах https://dom-food.com
        или https://domfood.kz и подтвердить согласие с условиями Соглашения путем нажатия кнопки
        «Согласиться и получить доступ».
        <p><br/></p>  2.4. Процедура регистрации и создание учетной записи:
        <p><br/></p>   2.4.1.Регистрация является бесплатной.
        <p><br/></p>  2.4.2.Пользователь обязан предоставить номер телефона, подтверждая согласие с условиями
        Соглашения. На этот номер будет отправлено SMS с кодом подтверждения.
        <p><br/></p>  2.4.3.После подтверждения регистрации Пользователь может создать личный аккаунт, указав имя,
        фамилию, адрес, электронную почту и, при желании, добавить фотографию.
        <p><br/></p> 2.5. Конфиденциальность данных для авторизации:
        <p><br/></p>   2.5.1.При регистрации Пользователь создает уникальные логин и пароль, которые являются
        конфиденциальными. Администрация Платформы рекомендует не использовать одинаковые
        учетные данные на других сайтах и платформах.
        <p><br/></p>  2.5.2.Администрация не несет ответственности за несанкционированный доступ к учетной записи
        Пользователя.
        <p><br/></p>  2.6. Точность и актуальность данных:
        <p><br/></p>  2.6.1.Каждый Пользователь обязан при регистрации на Платформе предоставлять свои
        достоверные контактные данные, включая имя и фамилию, электронную почту, номер телефона,
        иную информацию, согласно заполняемой заявке (для физического лица), или полное
        наименование, БИН и адрес местонахождения, электронную почту и номер телефона, а также
        полные ФИО, и контактный номер своего представителя, а также иную информацию, согласно
        заполняемой заявке, - для юридического лица.
        <p><br/></p>  2.6.2.В случае предоставления ложной информации или нарушения правил регистрации,
        Администрация вправе немедленно заблокировать учетную запись.
        <p><br/></p>  2.7. Проверка данных Администрацией: Администрация Платформы не проверяет (и не
        обязана проверять) личность Пользователей или правдивость, актуальность, полноту и/или
        подлинность предоставленных ими данных. Тем не менее, Администрация Платформы вправе в
        любое время проверить актуальность данных Пользователя, отраженных им в своем аккаунте,
        путем запроса оригиналов или заверенных надлежащим образом копий документов,
        подтверждающих указанные Пользователем данные. Не предоставление документации полностью
        или частично может быть приравнено к предоставлению недостоверной информации и влечет
        немедленную блокировку аккаунта Пользователя.
        <p><br/></p>   2.8. Обеспечение безопасности учетной записи: Пользователь несет полную ответственность
        за сохранность логина и пароля. Передача данных третьим лицам запрещена. При выявлении
        несанкционированного доступа Пользователь должен немедленно уведомить Администрацию.
        <p><br/></p>   2.9. Запрет на использование чужих имен и оскорбительных выражений: Пользователь не
        имеет права выбирать или использовать имя другого Пользователя или имя, которое нарушает права
        на товарный знак или другие права собственности какой-либо третьей стороны, использование
        которого является или может быть незаконным, что может вызвать путаницу в отношении личности
        Пользователя или его связи с другими организациями. Также Пользователю запрещено применять
        любые оскорбительные или вульгарные слова, наименования и выражения при регистрации
        аккаунта. В противном случае Администрация Платформы оставляет за собой право удалить любое
        вульгарное, неуместное или оскорбительное имя Пользователя с дальнейшей его блокировкой.
        <p><br/></p>  2.10. Пользователь при регистрации на Платформе обязуется принять условия Политики
        конфиденциальности и Политикой защиты данных, Политики использования файлов cookie,
        размещенных на сайте https://dom-food.com и https://domfood.kz/. В противном случае, в
        регистрации будет отказано.
        <p><br/></p>  2.11. Запрещенные действия с аккаунтом:
        <p><br/></p>  2.11.1. Пользователям запрещено использование своего аккаунте в незаконных целях, а
        также с целью нанесения ущерба правам и интересам третьих лиц или Платформе, ее содержимому
        и сервисам. Кроме того, Пользователям запрещено препятствовать нормальному использованию
        Платформы другими Пользователями.
        <p><br/></p>  2.11.2. Лица, нарушившие вышеуказанные обязательства, несут персональную полную
        ответственность за любые причиненные убытки или ущерб. При этом, Администрация Платформы
        вправе удалить аккаунт такого Пользователя или заблокировать его, а в необходимых случаях –
        передать всю информацию в правоохранительные органы.
        <p><br/></p> 2.12. Администрация Платформы вправе самостоятельно удалять также отзывы и оценки
        Пользователей о товарах и услугах, реализуемых посредством Платформы, в случаях нарушения
        Пользователями правил этики общения, если оставленные отзывы и оценки носят оскорбительный,
        унижающий характер, ненормативную лексику. Однако, Администрация вправе осуществить
        проверку фактов, указанных Пользователем на Платформе, и в случае их подтверждения, удалить
        аккаунт Продавца.
        <p><br/></p>   2.13. Пользователям категорически запрещено:
        <p><br/></p>   2.13.1. использовать без специального на то разрешения Администрации Платформы
        автоматизированные скрипты (программы, боты, краулеры) для сбора информации на Платформе
        и/или взаимодействия с Платформой и его функционалом;
        <p><br/></p>  2.13.2. осуществлять незаконные обработку, включая сбор, персональных данных других
        лиц;
        <p><br/></p>  2.13.3. осуществлять использование Платформы иным способом, кроме как через интерфейс,
        предоставленный Администрацией Платформы на соответствующем Ресурсе, за исключением
        случаев, когда такие действия были прямо разрешены Пользователю в соответствии с отдельным
        соглашением с Администрацией;
        <p><br/></p>  2.13.4. воспроизводить, дублировать, копировать, продавать, осуществлять торговые
        операции и перепродавать доступ к использованию Платформы для каких-либо целей;
        <p><br/></p> 2.13.5. использовать Платформу в целях иных, чем личное некоммерческого использование.
        <p><br/></p>  2.14. Удаление и блокировка учетной записи: Пользователи могут добровольно удалить свою
        учетную запись на Платформе. Кроме того, Администрация оставляет за собой право приостановить
        и/или деактивировать любую учетную запись Пользователя в случае, если Администрации станет
        известно о том, что Пользователь не соблюдает все основные требования к регистрации, или если у
        Администрации имеются основания подозревать мошенническое или незаконное использование
        учетной записи Пользователя, включая использование учетной записи Пользователя способом,
        противоречащим настоящим Общим условиям.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. Оформление заказов</h3>
    <p>
      <p><br/></p> 3.1. Учет характеристик товара:
      <p><br/></p> 3.1.1.При выборе товара на Платформе Пользователь обязан учитывать его состав, способ
      приготовления, вид упаковки и наличие противопоказаний.
      <p><br/></p>3.1.2.В случае необходимости уточнения состава или других характеристик, Пользователь должен
      связаться с Партнером Платформы.
      <p><br/></p> 3.1.3.Партнер несет полную ответственность за предоставление точной информации о составе,
      весе, цене, упаковке и условиях доставки товара.
      <p><br/></p>3.1.4.Платформа не несет ответственности за качество и количество товаров, предоставленных
      Партнером.
      <p><br/></p>3.2. Ценообразование и наценка:
      <p><br/></p>3.2.1.Цены, применимые к каждой услуге и/или товару, устанавливаются партнерами по их
      собственному усмотрению, и Администрация Платформы не имеет права вмешиваться в их
      предпринимательскую деятельность, в том числе ценовую политику. При этом, Администрацией
      платформы может устанавливаться наценку на товары и услуги, предоставляемые партнерами, в
      результате чего конечная цена для Пользователей будет определяться с учетом такой наценки.
      <p><br/></p> 3.3. При формировании заказа, Пользователю необходимо обратить внимание, что в некоторых
      случаях цена на товар, указанная партнерами, может не включать в себя стоимость упаковки,
      которая может взиматься с Пользователя дополнительно, а также включать в себя наценку
      Администрации платформы.
      <p><br/></p>3.4. Процесс оформления заказа: Пользователь может оформлять заказы на товары и услуги
      через Платформу, следуя инструкциям, предоставленным на сайте или в приложении по адресу
      https://dom-food.com и https://domfood.kz/.
      <p><br/></p>3.5. Оформление заказа считается завершенным только после его подтверждения
      Пользователем посредством приложения и оплаты через Платформу. При этом, Пользователь
      обязуется указать верные данные своей банковской карты (при необходимости). Обслуживающим
      банком возможно взимание комиссионного вознаграждения при осуществлении платежа, за
      которое Платформа не отвечает.
      <p><br/></p>3.6. Пользователь обязуется оплатить заказ в соответствии с установленными партнерами
      Платформы тарифами и выбранным способом оплаты.
      <p><br/></p> 3.7. Выбранный Пользователем Партнер обязуется подготовить и/или собрать и упаковать
      товары, указанные в заказе. Платформа не несет ответственности перед Пользователем за
      надлежащее исполнение заказа Партнером. Платформа только предоставляет Пользователю
      информацию о товарах, ценах, условиях и сроках доставки, а также возможность выбора удобного
      времени получения заказа.
      <p><br/></p> 3.8. При заказе услуг доставки Платформа уведомляет Курьера-партнера о том, что доступна
      возможность доставки. Порядок заказа услуги доставки и оплата размещены на сайте https://domfood.com и https://domfood.kz/.
      <p><br/></p> 3.9. Если Пользователь заказывает доставку заказа, заказ доставляется в место, указанное
      Пользователем при оформлении заказа.
      <p><br/></p> 3.9.1.Пользователь должен быть доступен для приема звонков по номеру телефона, указанному
      им в заявке при оформлении заказа.
      <p><br/></p> 3.9.2.Если предоставленный Пользователем номер телефона недоступен, доставка может быть
      отменена, а с Пользователя может взиматься полная стоимость заказа.
      <p><br/></p>3.10. Ответственность за точность данных:
      <p><br/></p> 3.10.1. Пользователи несут единоличную ответственность за предоставление точных адресов
      получения и доставки заказа.
      <p><br/></p>   3.10.2. Если Пользователь отсутствует по указанному адресу доставки и, следовательно,
      доставка заказа не представляется возможной, Пользователь обязуется оплатить полную
      стоимость услуги курьерской доставки и стоимость товара.
      <p><br/></p>3.10.3. Получение Пользователем заказа по указанному адресу доставки считается
      подтверждением факта передачи заказа.
      <p><br/></p> 3.10.4. Если заказ выполнен неправильно либо содержит неправильные или дефектные
      продукты, Пользователь должен немедленно связаться с Партнером сразу после получения заказа,
      а также известить об этом Администрацию Платформы путем отправки письма на электронную
      почту support@domfood.com.
      <p><br/></p>  3.10.5. Если в процессе оформления заказа Пользователь обнаружил ошибку, то
      Пользователь может связаться со службой поддержки Платформы путем отправки письма на
      электронную почту support@domfood.com для исправления ошибок в заказе до момента принятия
      заказа к выполнению. В случае обнаружения Пользователем ошибки после принятия заказа
      партнером к исполнению, ответственность за все последствия полностью ложится на
      Пользователя.
      <p><br/></p> 3.11. Уведомления о статусе заказа: В ходе подготовки заказа Пользователь будет получать
      уведомления о статусе заказа (подтверждение заказа, готовность и отправка, время доставки,
      нахождение в пути, номер телефона курьера и т.д.) через приложение или по электронной почте,
      указанной Пользователем при регистрации.
      <p><br/></p> 3.12. Время доставки: Время доставки каждого заказа рассчитывается индивидуально
      программными средствами, исходя из региона доставки. Пользователь принимает и соглашается,
      что любое время доставки является расчетным и приблизительным временем. Платформа и
      партнеры не могут гарантировать, что заказ будет доставлен Пользователю строго в указанное
      время. Сроки доставки товаров зависят от таких факторов, как транспортные пробки, часы пик,
      форс-мажорные обстоятельства (например, наличие ДТП по пути следования курьера) и погодные
      условия.
      <p><br/></p> 3.13. Компенсация за задержку доставки: При задержке доставки Пользователь может
      потребовать снижения стоимости доставки или компенсации в размере 30% от стоимости заказа
      за каждые 10 минут задержки.
      <p><br/></p>3.14. Оплата заказов:
      <p><br/></p>3.14.1. Оплата производится через Платформу в форме 100% предоплаты.
      <p><br/></p> 3.14.2. Доступны различные способы оплаты, включая банковские карты и электронные
      кошельки.
      <p><br/></p> 3.14.3. Карты не хранятся на серверах Платформы, а платежи обрабатываются через
      внешнюю платежную платформу.
      <p><br/></p>3.15. Валюта расчетов: Все платежи проводятся в тенге (KZT).
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. Отмена заказа и отказ от заказа</h3>
      <p>
        <p><br/></p> 4.1. Право на отмену до подтверждения:
        <p><br/></p> 4.1.1.Пользователь имеет право полностью или частично отменить заказ до момента его
        подтверждения Партнером, то есть до принятия заказа к исполнению. Отмена заказа
        подразумевает также отмену доставки товара.
        <p><br/></p> 4.1.2.После подтверждения заказа Партнером изменить или отменить заказ невозможно. В связи
        с этим Пользователь обязан проверить правильность своего выбора и данных в заявке на заказ
        перед его размещением.
        <p><br/></p> 4.1.3.Ответственность за правильность указанных данных в заявке целиком лежит на
        Пользователе.
        <p><br/></p> 4.2. Право Администрации на отмену заказа:
        <p><br/></p> 4.2.1.Администрация Платформы оставляет за собой право отменить заказ Пользователя в
        случае нарушения условий настоящего Соглашения, а также в случае технических сбоев или
        ошибок.
        <p><br/></p>  4.3. Возврат некачественного товара:
        <p><br/></p> 4.3.1.В случае получения товара ненадлежащего качества Пользователь имеет право на возврат
        товара Партнеру.
        <p><br/></p> 4.3.2.Для этого Пользователю необходимо незамедлительно связаться с Партнером, у которого
        был приобретен товар, и уведомить Администрацию Платформы о данном факте.
        <p><br/></p>4.3.3.Сообщение должно быть подкреплено фото- и видеоматериалами, подтверждающими факт
        некачественного блюда. В случае отравления товаром, необходимо предоставить медицинские
        документы, а затем — заключение экспертизы, подтверждающее некачественность товара.
        <p><br/></p> 4.3.4.В случае подтверждения Пользователь имеет право требовать возврата полной суммы и
        возмещения ущерба, нанесенного его жизни и здоровью.
        <p><br/></p>  4.4. Неполный или несоответствующий заказ: Если товар доставлен не полностью, не
        соответствует заявленному наименованию, или упаковка не соответствует требованиям,
        Пользователь вправе отказаться от заказа с полным возвратом суммы или потребовать уменьшения
        цены заказа.
        <p><br/></p> 4.5. В случае отмены заказа после его принятия к исполнению возврат средств не производится.
        <p><br/></p>  4.6. Дополнительная информация: Платформа оставляет за собой право запросить
        дополнительную информацию у Пользователя для подтверждения претензий, включая описание
        проблемы и документы, подтверждающие обоснованность претензий.
        <p><br/></p> 4.7. Ожидания по вкусу: Несоответствие вкусовых предпочтений Пользователя не признается
        фактом предоставления некачественного товара и не является основанием для возврата.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. Порядок возврата средств Пользователю</h3>
      <p>
        <p><br/></p>  5.1. Возврат средств при отмене заказа:
        <p><br/></p> 5.1.1.В случае отмены или отзыва заказа до его подтверждения Партнером, все произведенные
        Пользователем платежи, включая плату за доставку, подлежат возврату в полном объеме в
        соответствии с Политикой возврата, размещенной на сайте https://dom-food.com и
        https://domfood.kz/.
        <p><br/></p>  5.1.2.Возврат денежных средств осуществляется на банковскую карту Пользователя, с которой
        была произведена оплата, в течение 10 (десяти) календарных дней с момента получения
        Пользователем уведомления об отмене заказа.
        <p><br/></p>  5.1.3.В случае частичной отмены заказа возврат средств производится только за отмененную
        часть заказа. Прочие платежи возврату не подлежат.
        <p><br/></p> 5.2. Комиссия за обработку платежей: Платформа не взимает комиссию за оплату заказов,
        однако платежные системы могут удерживать комиссию за обработку транзакций. Эти комиссии
        возврату не подлежат.
        <p><br/></p> 5.3. Сроки возврата при форс-мажорных обстоятельствах: В случае возникновения форсмажорных обстоятельств, таких как технические сбои или блокировка банковских счетов, сроки
        возврата могут быть увеличены до 30 (тридцати) календарных дней. Платформа уведомляет
        Пользователя о причинах задержки и предполагаемых сроках возврата.
        <p><br/></p> 5.4. Уведомление о возврате средств: Платформа уведомляет Пользователя о возврате средств
        путем отправки уведомления на электронный адрес, указанный при регистрации, либо иным
        доступным способом (например, через SMS или уведомление в приложении).
        <p><br/></p>  5.5. Международные транзакции: В случае международных транзакций, возврат может занять
        до 30 (тридцати) календарных дней, в зависимости от политики банков и платежных систем.
        Пользователь уведомляется о возможности задержки возврата.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. Права и обязанности Пользователей</h3>
    <p>
      <p><br/></p>6.1. Права Пользователей:
      <p><br/></p> 6.1.1.Пользователи имеют право на доступ к информации о товарах и услугах, размещенных на
      Платформе, включая цены, условия доставки и отзывы других Пользователей.
      <p><br/></p> 6.1.2.Пользователи вправе получать качественные услуги, соответствующие описанию на
      Платформе.
      <p><br/></p> 6.1.3.Пользователи имеют право на возврат средств в случае некачественного приготовления
      товара, доставки испорченного товара или нарушения условий доставки, согласно Политике
      возврата.
      <p><br/></p>  6.1.4.Пользователи имеют право обращаться к партнерам Платформы по вопросам, связанным
      с оплатой и доставкой товара.
      <p><br/></p>  6.1.5.Пользователи могут в любой момент удалить свой аккаунт и запросить удаление всех своих
      данных с Платформы в соответствии с Политикой конфиденциальности.
      <p><br/></p> 6.2. Обязанности Пользователей:
      <p><br/></p>  6.2.1.Пользователи обязаны предоставлять точную и актуальную информацию при
      регистрации на Платформе и оформлении заказов, а также следить за актуальностью данных.
      <p><br/></p>  6.2.2.Пользователи обязаны оплачивать заказы в соответствии с установленными тарифами и
      условиями, указанными на Платформе.
      <p><br/></p>   6.2.3.Пользователи обязаны использовать Платформу исключительно в законных целях и не
      злоупотреблять её функциями (например, не оформлять ложные заказы).
      <p><br/></p>  6.2.4.Пользователи несут ответственность за действия третьих лиц, если те использовали их
      аккаунт, включая случаи несанкционированного доступа.
      <p><br/></p>  6.2.5.Пользователи имеют право оставлять честные и корректные отзывы о приобретённых
      товарах и услугах, соблюдая этику общения.
      <p><br/></p>  6.2.6.Пользователи не могут использовать Платформу для рекламных или коммерческих целей
      без согласия Администрации Платформы.
      <p><br/></p>   6.2.7.Пользователи обязаны соблюдать условия настоящего Соглашения, а также правила и
      политики, установленные Администрацией Платформы, такие как Политика возврата и Политика
      конфиденциальности.
      <p><br/></p>  6.3. Пользователю запрещено:
      <p><br/></p> 6.3.1.Воспроизводить, копировать, продавать, использовать для коммерческих целей любые части
      Платформы, а также нарушать права других Пользователей и партнеров, включая
      интеллектуальные права на объекты, принадлежащие Администрации Платформы (товарные
      знаки, логотипы и другие охраняемые законом объекты).
      <p><br/></p> 6.3.2.Выдавать себя за представителя другого Пользователя или иного физического или
      юридического лица.
      <p><br/></p>  6.3.3.Использовать учетную запись для выполнения функций Платформы в интересах другого
      Пользователя без его полномочий.
      <p><br/></p> 6.3.4.Передавать информацию о средствах доступа (логин и пароль) к учетной записи третьим
      лицам без согласия Пользователя.
      <p><br/></p> 6.3.5.Собирать и хранить персональные данные третьих лиц с нарушением законодательства
      Республики Казахстан.
      <p><br/></p> 6.3.6.Содействовать действиям, направленным на нарушение ограничений и запретов,
      установленных настоящим Соглашением.
      <p><br/></p>   6.3.7.Нарушать нормы действующего законодательства Республики Казахстан и иных
      нормативных актов, указанных в Соглашении.
      <p><br/></p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. Ответственность сторон</h3>
    <p>
      <p><br/></p>7.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои
      действия, связанные с использованием Платформы, в том числе в случаях, если такие действия
      приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение
      законодательства Республики Казахстан при использовании Платформы.
      <p><br/></p> 7.2. Пользователь несет ответственность за все действия (а также их последствия),
      совершаемые Пользователем, включая случаи добровольной передачи Пользователем данных для
      доступа к аккаунту Пользователя третьим лицам на любых условиях (в том числе по договорам или
      соглашениям).
      <p><br/></p> 7.3. Пользователь подтверждает, что Администрация Платформы не несет ответственности за
      прямые или косвенные убытки, которые были причинены в результате доступа третьих лиц к
      аккаунту Пользователя.
      <p><br/></p> 7.4. Администрация Платформы не несет никакой ответственности за задержки, сбои,
      неверную или несвоевременную доставку, удаление или несохранность каких-либо уведомлений.
      При этом Администрация оставляет за собой право повторного направления любого уведомления,
      в случае его неполучения Пользователем.
      <p><br/></p> 7.5. Администрация Платформы также не гарантирует бесперебойную работу и доступность
      системы. В случае технических сбоев Администрация обязуется приложить все возможные усилия
      для скорейшего восстановления работы, однако не несет ответственности за убытки, вызванные
      такими сбоями.
      <p><br/></p> 7.6. Администрация Платформы не несёт никакой ответственности за персональные
      данные Пользователя, которые были им предоставлены сторонним ресурсам и/или иным третьим
      лицам в случае перехода на них с Платформы.
      <p><br/></p> 7.7. Администрация Платформы не отвечает за возможную потерю и/или порчу данных,
      которая может произойти из-за нарушения Пользователем положений настоящего Соглашения, а
      также неправильного доступа и/или использования персонализированных сервисов Платформы.
      <p><br/></p> 7.8. Администрация Платформы не несет ответственности за задержки или сбои в
      предоставлении услуг, вызванные форс-мажорными обстоятельствами, такими как стихийные
      бедствия, технические сбои, перебои в работе интернет-провайдеров и прочие обстоятельства,
      находящиеся вне контроля Администрации Платформы.
      <p><br/></p> 7.9. Ответственность за действия несовершеннолетних, включая приобретение ими товаров
      из каталога на Платформе, лежит на законных представителях несовершеннолетних, и такие товары
      подлежат оплате в полном объеме, как если бы их заказал сам Пользователь.
      <p><br/></p>  7.10. Администрация Платформы не несет ответственности в случае неисполнения и/или
      ненадлежащего исполнения обязательств по продаже Пользователю товара надлежащего качества,
      в том числе в связи с предоставлением последним недостоверных и/или недействительных данных
      о себе, а равно невыполнение им условий настоящего Соглашения
      <p><br/></p> 7.11. Администрация Платформы не несет ответственность за сроки доставки товара, а также
      за любой ущерб или порчу продуктов в период доставки.
      <p><br/></p>7.12. Администрация Платформы не несет ответственности за перебои в работе сервиса,
      ошибки подключения, недоступность или сбои в работе службы доступа к Интернету, перебои в
      работе Интернета или любые другие вопросы, находящиеся за пределами ее контроля.
      <p><br/></p>7.13. Администрация Платформы не контролирует и не несет ответственности за контент,
      загружаемый Пользователями и/или партнерами, его актуальность, полноту и актуальность данных,
      а также иную информацию, предоставляемую Пользователями и партнерами на Платформу.
      <p><br/></p> 7.14. В связи с тем, что Администрация Платформы не несет ответственность за действия или
      бездействия третьих лиц, включая, но не ограничиваясь иных Пользователей и своих партнеров,
      Пользователи соглашаются, что все имеющиеся у них претензии по качеству товара и его доставке
      должны быть предъявлены непосредственно к партнерам. Никакие претензии и иски по качеству
      полученных Пользователем товаров и услуг посредством Платформы к Администрации Платформы
      удовлетворению не подлежат в виду определения Администрации Платформы как ненадлежащего
      ответчика.
      <p><br/></p>  7.15. Администрация Платформы не несет ответственности за содержание отзывов,
      опубликованных Пользователями в приложении и за его пределами, и не несет ответственности за
      любой ущерб и/или оскорбления, вызванные такими отзывами.
      <p><br/></p>   7.16. Администрация Платформы не занимается предварительной модерацией или цензурой
      информации Пользователей и предпринимает действия по защите прав и интересов лиц и
      обеспечению соблюдения требований законодательства Республики Казахстан только после
      обращения заинтересованного лица к Администрации Платформы в установленном порядке.
      <p><br/></p> 7.17. Администрация Платформы несет ответственность за убытки, причиненные
      Пользователю прямыми неправомерными действиями работниками Администрации Платформы. В
      случае возникновения убытков по вине Платформы, её ответственность ограничивается суммой
      заказа, который вызвал убытки. Платформа не несет ответственности за косвенные убытки или
      упущенную выгоду.
      <p><br/></p>  7.18. Администрация Платформы не дает никаких гарантий в отношении каких-либо
      рекомендаций. Любые рекомендации Пользователей или третьих лиц не имеют юридической силы
      и носят только рекомендательный характер, выражая субъективное мнение лица, давшего
      рекомендацию.
      <p><br/></p> 7.19. Администрация Платформы вправе без предварительного уведомления Пользователя
      передавать информацию о Пользователях правоохранительным органам по их запросам, согласно
      действующему законодательству.
      7.20. Пользователи несут ответственность за собственные действия в связи с использованием
      Платформы в соответствии с действующим законодательством Республики Казахстан. Нарушение
      настоящего Соглашения и действующего законодательства Республики Казахстан влечет за собой
      гражданско-правовую, административную и уголовную ответственность.
    </p>
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. Условия конфиденциальности</h3>
      <p>
        <p><br/></p>  8.1. Администрация Платформы осуществляет сбор и обработку персональных данных
        Пользователей в соответствии с Политикой конфиденциальности. Персональные данные могут
        включать сведения о заказах, платежах, местоположении и другую информацию, необходимую для
        предоставления услуг.
        <p><br/></p> 8.2. Платформа использует файлы cookie и аналогичные технологии для сбора данных о
        взаимодействии Пользователей с Платформой с целью улучшения работы сайта и предоставления
        персонализированных рекомендаций.
        <p><br/></p> 8.3. Персональные данные Пользователей используются исключительно для выполнения
        заказов, обеспечения безопасности транзакций и улучшения качества обслуживания.
        <p><br/></p> 8.4. Платформа может использовать данные Пользователей для анализа, улучшения работы
        системы, а также для предоставления персонализированных рекомендаций, уведомлений и
        маркетинговых предложений.
        <p><br/></p> 8.5. Платформа принимает все необходимые меры для защиты данных пользователей, и в
        случае утечки данных по вине платёжных систем Администрация не несет ответственности.
        <p><br/></p> 8.6. Персональные данные Пользователей не передаются третьим лицам без их согласия, за
        исключением случаев, предусмотренных законодательством Республики Казахстан или
        необходимости выполнения заказа (например, передача данных курьерам для доставки).
        <p><br/></p> 8.7. Персональные данные Пользователей хранятся на защищённых серверах на протяжении
        всего срока использования Платформы и в течение 2 лет после закрытия аккаунта Пользователя,
        если иное не предусмотрено законодательством Республики Казахстан.
        <p><br/></p>  8.8. Платформа принимает все необходимые меры для обеспечения безопасности
        персональных данных Пользователей, включая использование шифрования и современных методов
        защиты информации.
        <p><br/></p>  8.9. Пользователи имеют право запрашивать доступ к своим персональным данным, их
        исправление, удаление или ограничение обработки в соответствии с Политикой
        конфиденциальности.
        <p><br/></p> 8.10. Пользователи могут в любой момент отозвать согласие на обработку данных, однако это
        может ограничить возможность использования определённых функций Платформы.
        <p><br/></p>  8.11. Пользователь подтверждает согласие с тем, что его персональная информация,
        предоставленная при использовании Платформы, может быть передана партнёрам, с которыми
        Пользователь заключает договор.
      </p>

    </div>
  )
}

export default UserAgreement
