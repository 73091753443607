import Logo from '../images/logo.png';
import {useState} from "react";
import Burger from '../images/burger.svg'
import ThemeToggleButton from "./ThmeButton";
import CustomDrawer from "./CustomDrawer";
import { Link } from "react-router-dom";
import Language from "./DropDown";

const menu = [
  {title: 'EXHIBITION', href: 'exhibition'},
  {title: 'ABOUT', href: 'about'},
  {title: 'WORKS', href: 'works'},
  {title: 'GALLERY', href: 'gallery'},
  {title: 'CONTACTS', href: 'contacts'},
]

const Header = () => {
  const [showBurger, setShowBurger] = useState(false);

  const toggleMobileNav = () => {
    setShowBurger(!showBurger);
  };

  return (
    <div className='bg-lightBg dark:bg-darkBg text-lightText dark:text-darkText flex justify-between gap-x-4 md:gap-x-2 lg:gap-x-8 items-center py-4 px-7 md:px-20'>
      <Link to='/'>
        <img className="max-w-16" src={Logo} alt="png" />
      </Link>
      <div className='hidden md:flex gap-x-10'>
        {menu.map((item, index) => {
          return (
              <Link to={item.href} key={index} className="text-[#333F51] dark:text-white text-sm lg:text-base">{item.title}</Link>
          );
        })}
      </div>
    <div className="block md:hidden">
      {showBurger ?
        <div onClick={toggleMobileNav} className="close-icon open">
          <img className="h-full w-full rotate-90" src={Burger} alt="svg" />
        </div>
        :
        <div onClick={toggleMobileNav} className="burger-icon open">
          <img className="h-full w-full" src={Burger} alt="svg" />
        </div>}
    </div>
      <div className="hidden md:flex items-center gap-x-7">
        <div>
          <Language />
        </div>
        <ThemeToggleButton />
      </div>
      <CustomDrawer setShowBurger={setShowBurger} showBurger={showBurger} />
    </div>
  );
};

export default Header;
