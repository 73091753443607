import {useEffect} from "react";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
      <p className="mt-14">Настоящая Политика конфиденциальности персональных данных (далее — «Политика
        конфиденциальности») действует в отношении всей информации, которую Интернет-платформа
        сервиса гастрономического маркетплейса «DOM FOOD (ДОМ ФУД)», расположенная на
        доменных именах <a href="https://dom-food.com"> https://dom-food.com </a> и <a href="https://domfood.kz"> https://domfood.kz, </a>может получить о Пользователе в
        процессе использования Сайта, программных продуктов и сервисов платформы.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h3>
      <p className="mt-6"> <strong>1.1. </strong>В настоящей Политике конфиденциальности используются следующие термины:</p>

      <p>
        <p><br/></p>  1.1.1.«Администрация сайта» — уполномоченные сотрудники, действующие от имени
        Товарищества с ограниченной ответственностью «DOM FOOD (ДОМ ФУД)» (далее —
        «Компания»), которые осуществляют управление Сайтом, организуют и (или) осуществляют
        обработку персональных данных. Администрация сайта определяет цели и объем обработки
        персональных данных, а также действия, совершаемые с персональными данными.
        <p><br/></p>  1.1.2.«Персональные данные» — любая информация, относящаяся к прямо или косвенно
        определенному или определяемому физическому лицу (субъекту персональных данных).
        <p><br/></p>  1.1.3.«Обработка персональных данных» — любое действие или совокупность действий,
        совершаемых с использованием средств автоматизации или без них, в отношении персональных
        данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
        изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
        обезличивание, блокирование, удаление или уничтожение данных.
        <p><br/></p>  1.1.4.«Конфиденциальность персональных данных» — обязательное для соблюдения
        требование, возлагаемое на Оператора и всех лиц, имеющих доступ к персональным данным, не
        допускать их распространения без согласия субъекта данных или наличия иного законного
        основания.
        <p><br/></p>  1.1.5.«Пользователь» — любое лицо, имеющее доступ к Сайту через сеть Интернет и
        использующее его сервисы и функционал.
        <p><br/></p> 1.1.6.«Cookies» — небольшой фрагмент данных, отправляемый веб-сервером и сохраняемый на
        устройстве Пользователя. При каждом повторном обращении к Сайту веб-клиент или веб-браузер
        пересылает эти данные на сервер в составе HTTP-запроса.
        <p><br/></p>  1.1.7.«IP-адрес» — уникальный сетевой адрес устройства в компьютерной сети, построенной с
        использованием протокола IP, используемый для идентификации узла в сети.
        <p><br/></p> 1.1.8.«Оператор персональных данных» — юридическое или физическое лицо, которое
        самостоятельно или совместно с другими лицами организует и (или) осуществляет обработку
        персональных данных, а также определяет цели и средства обработки персональных данных. В
        данном случае, Оператором выступает ТОО «DOM FOOD (ДОМ ФУД)».
        <p><br/></p>  1.1.9.«Третьи лица» — физические или юридические лица, государственные органы, агентства
        или иные организации, которым могут быть переданы персональные данные Пользователя на
        основании настоящей Политики или законодательства Республики Казахстан.
        <p><br/></p>  1.1.10. «Согласие на обработку персональных данных» — добровольное, конкретное,
        информированное и однозначное выражение воли Пользователя, посредством которого он дает
        согласие на обработку его персональных данных.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p>  2.1. Согласие с Политикой: Использование Пользователем сайта Интернет-платформы
        означает его полное и безоговорочное согласие с настоящей Политикой конфиденциальности, а
        также с условиями обработки его персональных данных.
        <p><br/></p> 2.2. Прекращение использования: В случае несогласия с условиями Политики
        конфиденциальности, Пользователь обязан прекратить использование сайта и удалить свою
        учетную запись (если она была создана).
        <p><br/></p>  2.3. Ограничение ответственности за сторонние ресурсы: Настоящая Политика
        конфиденциальности распространяется исключительно на сайт Интернет-платформы сервиса
        гастрономического маркетплейса «DOM FOOD (ДОМ ФУД)». Компания не контролирует и не
        несет ответственности за содержимое, политику конфиденциальности и действия сторонних сайтов,
        на которые Пользователь может перейти по ссылкам, размещенным на сайте Интернет-платформы.
        <p><br/></p>  2.4. Проверка достоверности данных. Администрация сайта не несет ответственности за
        точность и актуальность персональных данных, предоставленных Пользователем. Вся
        ответственность за предоставление достоверной информации лежит на Пользователе.
        <p><br/></p>  2.5. Изменение Политики конфиденциальности: Компания оставляет за собой право вносить
        изменения в настоящую Политику конфиденциальности в одностороннем порядке. Уведомление о
        внесении изменений публикуется на сайте. Продолжение использования сайта после публикации
        изменений считается согласием Пользователя с новой редакцией Политики.
        <p><br/></p>  2.6. Язык и толкование: Если текст Политики конфиденциальности доступен на нескольких
        языках, приоритет имеет версия на русском языке. Все вопросы, связанные с толкованием
        положений Политики, регулируются законодательством Республики Казахстан.
        <p><br/></p>  2.7. Возрастные ограничения: Сайт и его сервисы предназначены для использования лицами,
        достигшими возраста 18 лет. Если Администрация сайта обнаружит, что персональные данные
        были предоставлены несовершеннолетним лицом без согласия родителей или законных
        представителей, такие данные будут немедленно удалены.
        <p><br/></p>   2.8. Файлы cookie и технологии отслеживания: Платформа использует файлы cookie и
        аналогичные технологии для улучшения пользовательского опыта и предоставления
        персонализированного контента. Пользователь может отключить использование файлов cookie в
        настройках браузера, но это может повлиять на функциональность Сайта.
        <p><br/></p>  2.9. Передача данных при реорганизации Компании: В случае слияния, приобретения или
        иной реорганизации Компании, персональные данные Пользователей могут быть переданы новому
        владельцу при условии соблюдения всех положений настоящей Политики конфиденциальности.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>
        <p>
          <p><br/></p> 3.1. Обязательства по защите данных: Настоящая Политика конфиденциальности
          устанавливает обязательства Администрации сайта по неразглашению и обеспечению защиты
          персональных данных, которые Пользователь предоставляет при регистрации на сайте https://domfood.com и https://domfood.kz или при оформлении заказа на приобретение Готовой продукции.
          <p><br/></p>3.2. Персональные данные, подлежащие обработке: Персональные данные, разрешенные к
          обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем
          путем заполнения регистрационной формы или оформления заказа на Сайте и включают в себя:
          <p><br/></p>3.2.1.Личная информация: имя, фамилия, номер телефона, адрес доставки, адрес электронной
          почты.
          <p><br/></p>3.2.2.Платежная информация: данные банковских карт и транзакций, которые обрабатываются
          через защищенные платежные системы.
          <p><br/></p> 3.2.3.История заказов: данные о предыдущих заказах, предпочтениях, отзывах и рейтингах
          Пользователя.
          <p><br/></p> 3.2.4.Технические данные: информация об устройстве Пользователя, IP-адрес, файлы cookie и
          локационные данные (при наличии согласия Пользователя).
          <p><br/></p>3.2.5.Аналитические данные: информация о взаимодействии с платформой, включая
          посещенные страницы, время на сайте, использование функций, для улучшения работы сервиса.
          <p><br/></p>3.2.6.Предпочтения в еде: информация о предпочтениях и аллергиях Пользователя для
          персонализированных рекомендаций.
          <p><br/></p> 3.2.7.Социальные сети: данные профиля (например, имя, фото), если Пользователь авторизуется
          через социальные сети и предоставляет на это согласие.
          <p><br/></p> 3.2.8.Маркетинговая информация: данные для отправки рекламных предложений, уведомлений
          и рассылок, основанных на поведении и предпочтениях Пользователя.
          <p><br/></p>3.3. Автоматически собираемая информация: Интернет-платформа защищает данные,
          которые автоматически передаются при просмотре страниц и рекламных блоков, на которых
          установлен статистический скрипт (пиксель). Эти данные включают:
          <p><br/></p>  • IP-адрес;
          <p><br/></p> • информация из файлов cookie;
          <p><br/></p>  • данные о браузере или программе, используемой для доступа к сайту;
          <p><br/></p>  • время доступа;
          <p><br/></p>  • адрес страницы, где размещен рекламный блок;
          <p><br/></p>  • реферер (адрес предыдущей страницы).
          <p><br/></p>  3.3.1.Отключение cookies: Отключение файлов cookie в настройках браузера может повлечь
          невозможность доступа к отдельным функциям и разделам Сайта, требующим авторизации.
          <p><br/></p> 3.3.2.Использование IP-адресов: Интернет-платформа собирает и анализирует IP-адреса
          посетителей для выявления и решения технических проблем, контроля законности финансовых
          транзакций и предотвращения мошенничества.
          <p><br/></p>  3.4. Защита дополнительной информации: Любая иная персональная информация, не
          указанная выше (например, используемые браузеры, операционные системы, история покупок),
          подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных
          пунктами 5.2 и 5.3 настоящей Политики конфиденциальности.
          <p><br/></p> 3.5. Обработка данных третьими лицами: В случае привлечения третьих лиц для выполнения
          услуг (например, курьерской доставки или платежных операций), персональные данные могут быть
          переданы таким лицам при условии соблюдения ими требований конфиденциальности и
          безопасности.
          <p><br/></p> 3.6. Хранение персональных данных: Платформа хранит персональные данные Пользователя
          только в течение времени, необходимого для достижения целей обработки, указанных в данной
          Политике. По истечении этого срока данные будут удалены или обезличены, если иное не
          предусмотрено законодательством.
          <p><br/></p> 3.7. Обезличивание персональных данных: Компания вправе использовать персональные
          данные в обезличенном виде для проведения статистических и аналитических исследований. В
          обезличенном виде данные не позволяют идентифицировать Пользователя и не требуют
          дополнительного согласия.
          <p><br/></p>3.8. Передача данных за пределы Казахстана: В случае необходимости передачи данных в
          другую страну для выполнения обязательств перед Пользователем, Компания гарантирует, что
          уровень защиты данных в принимающей стране соответствует требованиям законодательства
          Республики Казахстан и международным стандартам.
          <p><br/></p> 3.9. Ответственность за передачу данных: Платформа гарантирует, что при передаче данных
          третьим лицам (например, курьерским службам или платежным системам), они обязуются
          соблюдать требования конфиденциальности и безопасности данных.
          <p><br/></p> 3.10. Действия в случае утечки данных: В случае утечки персональных данных Компания
          обязуется:
          <p><br/></p> 3.10.1. немедленно уведомить уполномоченные государственные органы в соответствии с
          законодательством;
          <p><br/></p> 3.10.2. информировать пострадавших Пользователей о произошедшей утечке;
          <p><br/></p> 3.10.3. принять все необходимые меры для устранения последствий и предотвращения
          повторных утечек
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h3>
        <p> <p><br/></p>4.1. Персональные данные Пользователя могут использоваться Администрацией сайта
          интернет-платформы в следующих целях:
          <p><br/></p> 4.1.1.Идентификация Пользователя, зарегистрированного на Сайте, для оформления заказа и
          (или) заключения договора купли-продажи товара дистанционным способом через сервис
          гастрономического маркетплейса «DOM FOOD (ДОМ ФУД)».
          <p><br/></p>4.1.2.Предоставление доступа к персонализированным ресурсам Сайта.
          <p><br/></p>4.1.3.Установление обратной связи с Пользователем, включая направление уведомлений и
          запросов, касающихся использования Сайта, оказания услуг, а также обработка заявок и
          обращений от Пользователя.
          <p><br/></p> 4.1.4.Определение местоположения Пользователя для обеспечения безопасности и
          предотвращения мошенничества.
          <p><br/></p>4.1.5.Подтверждение достоверности и полноты персональных данных, предоставленных
          Пользователем.
          <p><br/></p>4.1.6.Создание учетной записи для совершения покупок и использования функций Сайта, если
          Пользователь дал на это согласие.
          <p><br/></p>4.1.7.Уведомление Пользователя о состоянии его заказа и предоставление актуальной
          информации о доставке.
          <p><br/></p>4.1.8.Обработка и получение платежей, подтверждение налога или налоговых льгот, решение
          вопросов оспаривания платежей, а также определение права Пользователя на получение
          кредитной линии (при наличии соответствующей услуги).
          <p><br/></p> 4.1.9.Предоставление клиентской и технической поддержки при возникновении проблем с
          использованием Сайта.
          <p><br/></p> 4.1.10. Информирование Пользователя о новых продуктах, специальных предложениях,
          акциях и новостях интернет-платформы или ее партнеров при наличии согласия Пользователя.
          <p><br/></p> 4.1.11. Осуществление рекламной деятельности на основе согласия Пользователя,
          включая отправку персонализированных предложений.
          <p><br/></p> 4.1.12. Предоставление доступа к сервисам партнеров, позволяющим Пользователю
          получать продукты, обновления и услуги.
          <p><br/></p>4.1.13. Проведение аналитики и статистических исследований, направленных на
          улучшение работы платформы и предоставление новых сервисов.
          <p><br/></p> 4.1.14. Соблюдение юридических обязательств, включая выполнение требований
          законодательства Республики Казахстан.
          <p><br/></p> 4.1.15. Мониторинг качества обслуживания и улучшение работы сервиса, включая
          анализ отзывов и предпочтений Пользователей.
          <p><br/></p>4.1.16. Персонализация взаимодействия с Пользователем: Данные могут использоваться
          для формирования персонализированных рекомендаций, предложений и интерфейсов, улучшения
          пользовательского опыта на основе предпочтений и истории взаимодействий.
          <p><br/></p>4.1.17. Отслеживание и предотвращение мошенничества. Платформа использует
          персональные и технические данные для мониторинга подозрительных действий и
          предотвращения мошеннических операций.
          <p><br/></p>4.1.18. Участие в маркетинговых и бонусных программах. Данные Пользователей могут
          использоваться для регистрации в акциях, конкурсах и других программах лояльности с согласия
          Пользователя.
          <p><br/></p> 4.1.19. Улучшение алгоритмов доставки. Локационные данные и информация о заказах
          могут использоваться для оптимизации маршрутов и улучшения времени доставки.
          <p><br/></p> 4.1.20. Выполнение правовых требований по защите данных. Обработка данных может
          осуществляться для выполнения требований законодательства о защите данных, включая
          управление запросами о доступе или удалении данных от Пользователей.
          <p><br/></p> 4.1.21. Трансграничная передача данных. В случае необходимости предоставления услуг
          за пределами Республики Казахстан, платформа может передавать данные за границу при
          соблюдении международных стандартов конфиденциальности.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИЫ</h3>
        <p>
          <p><br/></p>  5.1. Сроки обработки: Обработка персональных данных Пользователя осуществляется без
          ограничения срока до момента достижения целей обработки или до отзыва согласия Пользователя.
          Обработка данных производится любыми законными способами, включая автоматизированные и
          неавтоматизированные системы.
          <p><br/></p> 5.2. Передача данных третьим лицам: Пользователь соглашается, что Администрация сайта
          вправе передавать его персональные данные третьим лицам исключительно для выполнения
          заказов, оформленных через сайт DOM FOOD (ДОМ ФУД). В частности, данные могут быть
          переданы:
          <p><br/></p> 5.2.1.курьерским службам;
          <p><br/></p>  5.2.2.операторам почтовой связи;
          <p><br/></p>  5.2.3.операторам электросвязи;
          <p><br/></p>  5.2.4.платежным системам.
          <p><br/></p> 5.3. Передача данных государственным органам: Персональные данные Пользователя могут
          быть переданы уполномоченным органам государственной власти Республики Казахстан только на
          законных основаниях и в порядке, установленном законодательством.
          <p><br/></p>  5.4. Уведомление об утрате или разглашении данных: В случае утраты или разглашения
          персональных данных Администрация сайта обязуется немедленно уведомить Пользователя и
          принять меры по минимизации последствий.
          <p><br/></p> 5.5. Меры по защите персональных данных: Администрация сайта принимает все
          необходимые организационные и технические меры для защиты персональной информации от:
          <p><br/></p>  5.5.1.неправомерного или случайного доступа;
          <p><br/></p>  5.5.2.уничтожения, изменения или блокировки;
          <p><br/></p>  5.5.3.копирования, распространения или иных неправомерных действий третьих лиц.
          <p><br/></p>  5.6. Совместные меры по предотвращению ущерба: Администрация сайта и Пользователь
          обязуются сотрудничать и предпринимать все необходимые меры для предотвращения убытков и
          иных негативных последствий, вызванных утратой или разглашением персональных данных.
          <p><br/></p>  5.7. Условия хранения данных: Персональные данные Пользователя хранятся в течение срока,
          необходимого для выполнения целей, указанных в настоящей Политике, или в течение срока,
          предусмотренного законодательством Республики Казахстан. По истечении этого срока данные
          подлежат удалению или обезличиванию.
          <p><br/></p>  5.8. Отзыв согласия на обработку данных: Пользователь имеет право в любое время отозвать
          свое согласие на обработку персональных данных путем направления письменного уведомления
          Администрации сайта. После получения уведомления обработка данных будет прекращена, за
          исключением случаев, предусмотренных законодательством.
          <p><br/></p>  5.9. Трансграничная передача данных: Если выполнение заказа или оказание услуг требует
          передачи данных за пределы Республики Казахстан, Администрация сайта обеспечивает
          соблюдение всех требований по защите персональных данных, предусмотренных международными
          стандартами.
          <p><br/></p>5.10. Информирование о правах Пользователя: Администрация сайта обязуется
          информировать Пользователя о его правах в отношении персональных данных, включая:
          <p><br/></p> 5.10.1. право на доступ к данным и их исправление;
          <p><br/></p>  5.10.2. право на удаление данных («право быть забытым»);
          <p><br/></p>  5.10.3. право на ограничение обработки данных;
          <p><br/></p> 5.10.4. право на возражение против обработки данных, включая автоматизированное
          принятие решений и профилирование;
          <p><br/></p>  5.10.5. право на перенос данных в машиночитаемом формате.
          <p><br/></p>  5.11. Журналирование доступа к данным: Платформа ведет журналирование доступа к
          персональным данным для контроля и предотвращения несанкционированных действий. Данные о
          доступе хранятся в течение периода, предусмотренного законодательством, и используются для
          аудита и расследования инцидентов.
          <p><br/></p> 5.12. Порядок действий при инцидентах:
          <p><br/></p>  5.12.1. В случае инцидента, связанного с утратой или компрометацией данных,
          Администрация сайта: немедленно запускает внутреннее расследование;
          <p><br/></p> 5.12.2. уведомляет соответствующие органы власти (при необходимости);
          <p><br/></p> 5.12.3. информирует всех пострадавших пользователей и предоставляет рекомендации по
          минимизации рисков.
          <p><br/></p> 5.13. Использование сторонних сервисов и обработчиков данных: Платформа может
          привлекать сторонних обработчиков данных для выполнения отдельных функций (например,
          хранение данных в облаке). В таких случаях Администрация сайта обязуется заключать с
          обработчиками соглашения, обеспечивающие соблюдение стандартов безопасности и
          конфиденциальности.
          <p><br/></p>   5.14. Актуализация данных Пользователя: Пользователь обязуется своевременно обновлять
          предоставленные персональные данные в случае их изменения, чтобы обеспечить корректность и
          актуальность информации.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОБЯЗАТЕЛЬСТВА СТОРОНЫ</h3>
      <p>
        <p><br/></p>  6.1. Обязанности Пользователя:
        <p><br/></p>  6.1.1.Предоставить достоверные и актуальные данные, необходимые для регистрации и
        пользования Сайтом интернет-платформы.
        <p><br/></p>  6.1.2.Своевременно обновлять и дополнять предоставленную информацию о персональных
        данных в случае их изменения, чтобы обеспечить точность и актуальность.
        <p><br/></p>  6.1.3.Использовать Сайт и предоставленные сервисы в соответствии с условиями Политики
        конфиденциальности и действующим законодательством.
        <p><br/></p> 6.1.4.Обеспечивать конфиденциальность своих учетных данных (логина и пароля) и не передавать
        их третьим лицам. В случае утраты доступа или подозрения на несанкционированный доступ к
        аккаунту, Пользователь обязан незамедлительно уведомить Администрацию сайта.
        <p><br/></p>  6.1.5.Пользователь обязуется предоставлять только свои персональные данные. Передача данных
        третьих лиц без их согласия не допускается, за исключением случаев, предусмотренных
        законодательством.
        <p><br/></p>  6.1.6.В случае нарушения условий Политики конфиденциальности или злоупотребления
        функционалом Сайта, Пользователь обязан устранить последствия такого нарушения и возместить
        возможный ущерб.
        <p><br/></p> 6.2. Обязанности Администрации сайта:
        <p><br/></p>   6.2.1.Использовать полученные персональные данные исключительно для целей, указанных в
        пункте 4 настоящей Политики конфиденциальности.
        <p><br/></p> 6.2.2.Обеспечить сохранность конфиденциальной информации, не разглашать ее без
        предварительного письменного согласия Пользователя, а также не осуществлять продажу, обмен,
        публикацию или иное разглашение персональных данных, за исключением случаев,
        предусмотренных пунктами 5.2 и 5.3 настоящей Политики.
        <p><br/></p>  6.2.3.Применять все необходимые организационные и технические меры предосторожности для
        защиты конфиденциальности персональных данных Пользователя, в соответствии с передовыми
        стандартами безопасности, используемыми в деловом обороте.
        <p><br/></p>   6.2.4.Заблокировать персональные данные, относящиеся к Пользователю, по запросу самого
        Пользователя, его законного представителя или уполномоченного органа по защите прав
        субъектов персональных данных, на время проведения проверки в случае выявления
        недостоверности данных или неправомерных действий.
        <p><br/></p>  6.2.5.Уведомить Пользователя в случае утечки, утраты или несанкционированного доступа к его
        персональным данным и предпринять все необходимые меры для минимизации ущерба.
        <p><br/></p>  6.2.6.Обеспечить возможность Пользователю реализовать свои права на доступ, исправление,
        ограничение обработки и удаление персональных данных в соответствии с законодательством
        Республики Казахстан.
        <p><br/></p>  6.2.7.Гарантировать удаление или обезличивание персональных данных после достижения целей
        их обработки, если иное не предусмотрено законодательством.
        <p><br/></p> 6.2.8.Администрация сайта обязуется информировать Пользователя о любых изменениях в
        Политике конфиденциальности и предоставлять возможность ознакомиться с обновленной
        версией до продолжения использования Сайта.
        <p><br/></p>  6.2.9.В случае привлечения сторонних обработчиков данных (например, облачных провайдеров
        или аналитических сервисов), Администрация обязуется заключать с ними договоры,
        гарантирующие защиту персональных данных на уровне не ниже предусмотренного настоящей
        Политикой.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
        <p>
          <p><br/></p>  7.1. Ответственность Администрации сайта: Администрация сайта, не исполнившая свои
          обязательства, несет ответственность за убытки, понесенные Пользователем в результате
          неправомерного использования его персональных данных, в соответствии с законодательством
          Республики Казахстан. Ответственность не наступает в случаях, предусмотренных пунктами 5.2.,
          5.3. и 7.2 настоящей Политики конфиденциальности.
          <p><br/></p>  7.2. Освобождение от ответственности Администрации сайта: Администрация сайта не несет
          ответственности за утрату или разглашение конфиденциальной информации, если:
          <p><br/></p> 7.2.1.Данная информация стала публичным достоянием до ее утраты или разглашения.
          <p><br/></p>   7.2.2.Информация была получена от третьей стороны до момента ее получения Администрацией
          сайта.
          <p><br/></p>  7.2.3.Информация была раскрыта с согласия Пользователя.
          <p><br/></p>  7.2.4.Утечка информации произошла в результате несанкционированного доступа третьих лиц
          (например, в результате хакерской атаки), при условии, что Администрация сайта приняла все
          необходимые меры для защиты данных.
          <p><br/></p>  7.2.5.Пользователь сам добровольно передал свои учетные данные (логин, пароль) третьим лицам
          или не обеспечил их конфиденциальность.
          <p><br/></p>  7.3. Ответственность Пользователя:
          <p><br/></p>  7.3.1.Пользователь несет ответственность за достоверность предоставленных данных и
          своевременное их обновление.
          <p><br/></p>  7.3.2.В случае неправомерных действий Пользователя, которые привели к нарушению Политики
          конфиденциальности или законодательных норм, Администрация сайта вправе приостановить или
          ограничить доступ Пользователя к сервисам платформы, а также потребовать возмещения
          причиненного ущерба.
          <p><br/></p> 7.3.3.Пользователь несет ответственность за последствия передачи своих учетных данных третьим
          лицам и обязуется уведомить Администрацию сайта при подозрении на несанкционированный
          доступ.
          <p><br/></p>   7.4. Ограничение ответственности: В случае форс-мажорных обстоятельств, таких как
          природные катастрофы, сбои в работе сети Интернет, действия государственных органов или иные
          обстоятельства непреодолимой силы, Администрация сайта не несет ответственности за
          невыполнение обязательств, предусмотренных настоящей Политикой.
          <p><br/></p>   7.5. Уведомление о нарушениях: В случае утраты, утечки или неправомерного использования
          данных Администрация сайта обязуется:
          <p><br/></p>  7.5.1.уведомить Пользователя в течение 72 часов с момента обнаружения инцидента;
          <p><br/></p>  7.5.2.предоставить подробную информацию о характере инцидента и принятых мерах по
          устранению его последствий;
          <p><br/></p>  7.5.3.предложить Пользователю рекомендации по минимизации возможных рисков.
          <p><br/></p>  7.6. Взаимодействие с государственными органами: При возникновении необходимости
          сотрудничества с государственными органами в связи с нарушениями прав пользователей или
          утечкой данных, Администрация обязуется предоставлять соответствующим органам необходимую
          информацию в пределах, установленных законодательством, и информировать об этом
          Пользователя.
          <p><br/></p>  7.7. Компенсация убытков: Если Пользователь понес убытки в результате нарушения
          обязательств Администрацией сайта, компенсация может быть выплачена только в пределах
          доказанных прямых убытков. Упущенная выгода, моральный ущерб или косвенные убытки не
          подлежат возмещению, если иное не предусмотрено законодательством.
          <p><br/></p>  7.8. Уведомление о передаче данных третьим лицам: Если персональные данные передаются
          третьим лицам, Администрация сайта обязуется уведомить об этом Пользователя, за исключением
          случаев, предусмотренных законодательством Республики Казахстан или настоящей Политикой.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. РАЗРЕШЕНИЕ СПОРОВ</h3>
        <p>
          <p><br/></p> 8.1. Досудебное урегулирование: До обращения в суд с иском по спорам, возникающим из
          отношений между Пользователем и Администрацией сайта, обязательным является предъявление
          претензии (письменного предложения о добровольном урегулировании спора).
          <p><br/></p> 8.2. Сроки рассмотрения претензии: Получатель претензии обязан в течение 30 календарных
          дней с момента получения письменно уведомить заявителя о результатах рассмотрения претензии
          и предложенных мерах по урегулированию.
          <p><br/></p> 8.3. Судебное разбирательство: Если стороны не пришли к соглашению в досудебном порядке,
          спор подлежит передаче на рассмотрение в компетентный суд в соответствии с действующим
          законодательством Республики Казахстан.
          <p><br/></p> 8.4. Применимое право: К настоящей Политике конфиденциальности и всем отношениям
          между Пользователем и Администрацией сайта применяется действующее законодательство
          Республики Казахстан.
          <p><br/></p>  8.5. Язык и толкование споров: Если текст Политики конфиденциальности доступен на
          нескольких языках, приоритет имеет версия на русском языке. В случае разногласий по
          толкованию положений настоящей Политики, стороны руководствуются версией, опубликованной
          на русском языке.
          <p><br/></p>  8.6. Форс-мажор: Администрация сайта освобождается от ответственности за нарушение
          условий Политики конфиденциальности, вызванное обстоятельствами непреодолимой силы (форсмажор), такими как:
          <p><br/></p>  8.6.1.природные катастрофы, пожары, наводнения;
          <p><br/></p>  8.6.2.военные действия или акты терроризма;
          <p><br/></p> 8.6.3.действия государственных органов или изменения в законодательстве;
          <p><br/></p>  8.6.4.сбои в работе сетей связи или инфраструктуры, находящиеся вне контроля Администрации
          сайта.
          <p><br/></p>  8.7. Медиация и альтернативные способы разрешения споров: Стороны могут договориться
          об урегулировании спора с привлечением посредника (медиатора) или с использованием других
          альтернативных способов разрешения споров до передачи дела в суд.
          <p><br/></p>  8.8. Сроки давности: Стороны соглашаются, что срок исковой давности для подачи претензий
          и споров, связанных с нарушением условий данной Политики конфиденциальности, составляет
          один год с момента обнаружения нарушения.
          <p><br/></p>  8.9. Уведомление о праве на подачу претензии: Администрация сайта обязуется уведомить
          Пользователя о его праве подать претензию в случае, если Пользователь выразил недовольство
          работой сервиса, обработкой его данных или нарушением его прав.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
      <p>
        <p><br/></p>  9.1. Право на внесение изменений: Администрация сайта оставляет за собой право вносить
        изменения в настоящую Политику конфиденциальности без предварительного согласия
        Пользователя. Пользователь обязан самостоятельно отслеживать актуальные изменения.
        <p><br/></p>  9.2. Вступление изменений в силу: Новая редакция Политики конфиденциальности вступает в
        силу с момента ее размещения на Сайте интернет-платформы, если иное не предусмотрено новой
        редакцией.
        <p><br/></p> 9.3. Уведомление об изменениях (при необходимости): В случае значительных изменений,
        касающихся обработки персональных данных, Администрация сайта вправе уведомить
        Пользователя через электронную почту или другим доступным способом, чтобы обеспечить
        осведомленность Пользователя.
        <p><br/></p>  9.4. Обратная связь и вопросы: Все предложения, вопросы или жалобы, связанные с настоящей
        Политикой конфиденциальности, следует направлять на электронную почту:
        support@domfood.com.
        <p><br/></p>  9.5. Доступ к актуальной версии Политики: Актуальная версия Политики
        конфиденциальности всегда доступна по следующим адресам: https://dom-food.com/ и
        https://domfood.kz/.
        <p><br/></p>  9.6. Архив версий Политики: В целях прозрачности Администрация сайта вправе хранить
        архив предыдущих версий Политики конфиденциальности. Доступ к архиву может быть
        предоставлен Пользователю по запросу.
        <p><br/></p>  9.7. Применение в случае реорганизации компании: В случае реорганизации, слияния или
        продажи компании все права и обязательства в отношении персональных данных будут переданы
        новому владельцу на условиях, не менее строгих, чем предусмотренные настоящей Политикой
        конфиденциальности.
        <p><br/></p> 9.8. Отдельные положения: Если какое-либо из положений данной Политики будет признано
        недействительным или утратит юридическую силу, это не влияет на действительность остальных
        положений Политики. В таком случае стороны соглашаются заменить недействительное положение
        новым, максимально соответствующим цели изначального.
      </p>
    </div>
  )
}

export default PrivacyPolicy;
