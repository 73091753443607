import {useEffect, useState} from "react";
import {baseApiUrl, getDynamicData} from "../service/base-url";
import TabPanel from "../components/TabPanel";
import ShowProducts from "../components/ShowProducts";
import axios from "axios";
import toast from "react-hot-toast";

const Works  = () => {
  const [categories, setCategories] = useState([])
  const [works, setWorks] = useState([])

  useEffect(() => {
    getDynamicData('work_categories', setCategories, null)
  }, [])

  useEffect(() => {
    getDynamicData('works', setWorks, null)
  }, [])


  const sortWorks = async (category_id) => {
    try {
      const response = await axios.get(`${baseApiUrl}/works?category_id=${category_id}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      const dataRes = response.data
      if (dataRes) {
        setWorks(dataRes.data)
      }
    } catch (error) {
      toast.error('Please try again.')
      console.error('Error:', error)
    }
  }


  console.log(categories,'categories')
  console.log(works,'works')

  return (
    <div className="px-5 md:px-20">
      <TabPanel sortWorks={sortWorks} categories={categories} />
      <ShowProducts works={works} />
    </div>
  )
}

export default Works


