const TabPanel = ({ categories, sortWorks }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between mt-12 pb-4 border-b">
      <p className="text-darkBg dark:text-lightBg text-2xl lg:text-4xl">Works</p>
      <div className="flex flex-col md:flex-row items-start md:items-center gap-x-3 md:gap-x-4 lg:gap-x-8 mt-4 lg:mt-0 space-y-2 md:space-y-0">
        {categories.map((item, index) => {
          return (
            <div onClick={() => sortWorks(item.id)} className="cursor-pointer" key={index}>
              <p className="text-darkBg dark:text-lightBg font-custom tracking-wide">{item.name}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TabPanel;
