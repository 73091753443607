import React from 'react';
import { useTheme } from "../providers/themeProvider";
import Arev from '../images/arev.svg'
import Lusin from '../images/lusin.svg'

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useTheme();

  const handleToggle = (selectedTheme) => {
    if (theme !== selectedTheme) {
      toggleTheme();
    }
  };


  return (
    <div className="flex space-x-2 dark:bg-[#050404] bg-[#F1F1F1] px-2 rounded-2xl">
      <button
        onClick={() => handleToggle('light')}
        className={`p-2 transition rounded-full ${
          theme === 'dark' ? 'text-white' : 'bg-[#353534] text-gray-700'
        }`}
        aria-label="Switch to Dark Theme"
      >
       <img src={Arev} alt='svg' />
      </button>
      <button
        onClick={() => handleToggle('dark')}
        className={`p-2 transition rounded-full ${
          theme === 'light' ? 'text-white' : 'bg-gray-200 text-gray-700'
        }`}
        aria-label="Switch to Light Theme"
      >
        <img src={Lusin} alt='svg' />
      </button>
    </div>
  );
};

export default ThemeToggleButton;
