import ContactUsBlock from "../components/ContactUsBlock";

const Contacts  = () => {
  return (
    <div>
     <ContactUsBlock />
    </div>
  )
}

export default Contacts


