import BlockOne from "../components/HomePage/BlockOne";
import BlockTwo from "../components/HomePage/BlockTwo";
import { useEffect, useState } from "react";
import { getDynamicData } from "../service/base-url";
import BlockThree from "../components/HomePage/BlockThree";
import BlockFore from "../components/HomePage/BlockFore";
import BlockFive from "../components/HomePage/BlockFive";
import ContactUsBlock from "../components/ContactUsBlock";

const Home  = () => {
  const [homeData, setHomeData] = useState()

  useEffect(() => {
    getDynamicData('home', setHomeData, null)
  }, [])

  return (
    <>
      <BlockOne data={homeData?.home} />
      <BlockTwo data={homeData?.biography} />
      <BlockThree data={homeData?.works}/>
      <BlockFore data={homeData?.home} />
      <BlockFive data={homeData?.exhibitions} />
      <ContactUsBlock />
    </>
  )
}
export default Home
