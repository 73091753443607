import './App.css';
import Header from './components/Header';
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Home from "./pages/Home";
import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Exhibition from "./pages/Exhibition";
import About from "./pages/About";
import Works from "./pages/Works";
import Gallery from "./pages/Gallery";
import {ThemeProvider} from "./providers/themeProvider";
import Contacts from "./pages/Contacts";
import Work from "./pages/Work";

function App() {
  return (
    <ThemeProvider>
      <div className="max-w-[1680px] m-auto w-full bg-lightBg dark:bg-darkBg">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/exhibition" element={<Exhibition />} />
            <Route path="/about" element={<About />} />
            <Route path="/works" element={<Works />} />
            <Route path="/works/:id" element={<Work />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/user_agreement" element={<UserAgreement />} />
          </Routes>
          <Footer />
          <Toaster />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
